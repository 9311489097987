import { useEffect, useState } from "react";
import { 
  Card, 
  CardBody, 
  CardTitle 
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { getChartStats } from "apis/dashboard";

const TransactionsChart = () => {
  const [chartsData, setChartsData] = useState(null);
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getChartStats();
        const totals = result?.totals || {};
        const monthly = totals?.monthly || {};

        setSeriesData([
          {
            name: "Deposits",
            data: monthly.deposits || Array(12).fill(0),
          },
          {
            name: "Withdrawals",
            data: monthly.withdrawals || Array(12).fill(0),
          },
          {
            name: "Net",
            data: monthly.net || Array(12).fill(0),
          },
        ]);

        setChartsData(totals);
      } catch (error) {
        console.error("Error fetching chart stats:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Card className="card-animate">
      <CardBody>
        <CardTitle>
          <h5>{"Yearly Transactions Overview"}</h5>
        </CardTitle>
        <ReactApexChart
          options={{
            chart: {
              height: 400,
              type: "area",
              dataLabels: {
                enabled: false,
              },
              toolbar: {
                show: true,
              },
            },
            colors: ["#21C78F", "#B11233", "#0D86D5"],
            stroke: {
              curve: "smooth",
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
              },
            },
          }}
          series={seriesData}
          type="area"
          height={500}
        />
        <div className="d-flex justify-content-center gap-4 mt-3">
          <div className="d-flex align-items-center gap-1">
            <span className="bolt green"></span>
            <span>
              Total Deposits:{" "}
              <span className="fw-bold">
                {chartsData?.allTime?.totalDeposits?.toLocaleString() || 0}
              </span>
            </span>
          </div>
          <div className="d-flex align-items-center gap-1">
            <span className="bolt red"></span>
            <span>
              Total Withdrawals:{" "}
              <span className="fw-bold">
                {chartsData?.allTime?.totalWithdrawals?.toLocaleString() || 0}
              </span>
            </span>
          </div>
          <div className="d-flex align-items-center gap-1">
            <span className="bolt blue"></span>
            <span>
              Net Total:{" "}
              <span className="fw-bold">
                {chartsData?.allTime?.totalNet?.toLocaleString() || 0}
              </span>
            </span>
          </div>
          <div className="d-flex align-items-center gap-1">
            <span className="bolt bg-dark"></span>
            <span>
              Total Clients:{" "}
              <span className="fw-bold">
                {chartsData?.customersCount?.toLocaleString() || 0}
              </span>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TransactionsChart;
